/* CartCheckoutPage.css */
.cart-checkout-page {
    font-family: var(--main-font);
  }
  
  .cart-checkout-page .intro {
    background-color: var(--primary-black);
    color: var(--primary-white);
    text-align: center;
    padding: 60px 0;
  }
  
  .cart-checkout-page .intro h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    color: var(--primary-yellow);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .cart-checkout-page .intro .subtitle {
    font-size: 1.2rem;
    font-style: italic;
  }
  
  .cart-checkout-content {
    padding: 40px 0;
    background-color: #f5f5f5;
  }
  
  .cart-checkout-section {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  }
  
  .cart-checkout-section h2 {
    color: var(--primary-red);
    margin-bottom: 20px;
    font-size: 1.8rem;
  }
  
  /* Cart Table Styles */
  .cart-checkout-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 15px;
  }
  
  .cart-checkout-table th {
    text-align: left;
    padding: 10px;
    border-bottom: 2px solid #ddd;
    color: var(--primary-black);
  }
  
  .cart-checkout-item td {
    padding: 15px;
    background-color: #f9f9f9;
    vertical-align: middle;
  }
  
  .cart-checkout-item td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  
  .cart-checkout-item td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  .cart-checkout-item-info {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .cart-checkout-item-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .cart-checkout-item-quantity {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .cart-checkout-item-quantity button {
    background-color: var(--primary-yellow);
    border: none;
    color: var(--primary-black);
    width: 30px;
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }
  
  .cart-checkout-item-quantity button:hover {
    background-color: var(--primary-red);
    color: var(--primary-white);
  }
  
  .cart-checkout-item-quantity input {
    width: 50px;
    text-align: center;
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 4px;
  }
  
  .cart-checkout-btn-remove {
    background: none;
    border: none;
    color: var(--primary-red);
    cursor: pointer;
    font-size: 1.2rem;
    transition: color 0.3s ease;
  }
  
  .cart-checkout-btn-remove:hover {
    color: darkred;
  }
  
  /* Summary Styles */
  .cart-checkout-summary {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
  }
  
  .cart-checkout-summary h3 {
    color: var(--primary-red);
    margin-bottom: 15px;
  }
  
  .cart-checkout-summary-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 5px 0;
  }
  
  .cart-checkout-summary-total {
    border-top: 2px solid #ddd;
    margin-top: 10px;
    padding-top: 10px;
    font-weight: bold;
    font-size: 1.2rem;
  }
  
  /* Form Styles */
  .cart-checkout-form-group {
    margin-bottom: 30px;
  }
  
  .cart-checkout-form-group h3 {
    color: var(--primary-red);
    margin-bottom: 15px;
    font-size: 1.2rem;
  }
  
  .cart-checkout-form-group input,
  .cart-checkout-form-group select {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .cart-checkout-location-group {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    gap: 15px;
  }
  
  .cart-checkout-payment-option {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .cart-checkout-card-icons {
    display: flex;
    gap: 10px;
    margin-left: 15px;
  }
  
  .cart-checkout-card-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
  
  /* Button Styles */
  .cart-checkout-btn-primary,
  .cart-checkout-btn-secondary {
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .cart-checkout-btn-primary {
    background-color: var(--primary-red);
    color: var(--primary-white);
  }
  
  .cart-checkout-btn-secondary {
    background-color: var(--primary-white);
    color: var(--primary-black);
    border: 1px solid #ddd;
  }
  
  .cart-checkout-btn-primary:hover {
    background-color: var(--primary-yellow);
    color: var(--primary-black);
  }
  
  .cart-checkout-btn-secondary:hover {
    background-color: #f5f5f5;
  }
  
  /* Confirmation Styles */
  .cart-checkout-confirmation {
    text-align: center;
    padding: 40px;
  }
  
  .cart-checkout-confirmation h2 {
    color: var(--primary-red);
    margin-bottom: 20px;
  }
  
  .cart-checkout-order-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-top: 30px;
    text-align: left;
  }
  
  /* Empty Cart Styles */
  .empty-cart {
    text-align: center;
    padding: 40px;
  }
  
  .empty-cart p {
    margin-bottom: 20px;
    color: #666;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .cart-checkout-item td {
      padding: 10px;
    }
  
    .cart-checkout-item-info {
      flex-direction: column;
      text-align: center;
    }
  
    .cart-checkout-location-group {
      grid-template-columns: 1fr;
    }
  
    .cart-checkout-card-details {
      grid-template-columns: 1fr;
    }
  
    .cart-checkout-order-details {
      grid-template-columns: 1fr;
    }
  
    .cart-checkout-table thead {
      display: none;
    }
  
    .cart-checkout-item td {
      display: block;
      text-align: right;
      padding-left: 50%;
      position: relative;
    }
  
    .cart-checkout-item td::before {
      content: attr(data-label);
      position: absolute;
      left: 10px;
      font-weight: bold;
    }
  }
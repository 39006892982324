@tailwind base;
@tailwind components;
@tailwind utilities;

/* Root Variables */
:root {
  --primary-red: #C13B1D;
  --primary-yellow: #F9C32D;
  --primary-black: #000000;
  --primary-white: #FFFFFF;
}

/* Base Styles */
@layer base {
  html {
    @apply scroll-smooth;
  }

  body {
    @apply font-main antialiased min-w-full min-h-screen overflow-y-auto overflow-x-hidden;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply text-primary-black mb-5;
  }

  a {
    @apply text-primary-red no-underline hover:underline;
  }
}

/* Components */
@layer components {
  .container {
    @apply w-full max-w-[1200px] mx-auto px-5 box-border;
  }

  .btn {
    @apply inline-block px-6 py-3 text-base font-bold text-center no-underline border-none rounded-lg cursor-pointer transition-all duration-400 ease-in-out hover:scale-110 hover:shadow-lg;
  }

  .btn-primary {
    @apply bg-primary-red text-primary-white hover:bg-primary-yellow hover:text-primary-black hover:no-underline;
  }

  .btn-secondary {
    @apply bg-primary-yellow text-primary-black hover:bg-primary-red hover:text-primary-white hover:no-underline;
  }

  .skip-to-main-content-link {
    @apply absolute left-[-9999px] z-[999] p-4 bg-primary-black text-primary-white opacity-0 focus:left-1/2 focus:-translate-x-1/2 focus:opacity-100;
  }
}

@layer utilities {
  .text-shadow {
    text-shadow: 2px 2px 4px var(--tw-shadow-color);
  }
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

.scrollbar-none {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.animate-scroll, .animate-scroll-right {
  animation-play-state: running;
  transition: animation-play-state 0.3s ease-out;
}

.animate-pause {
  animation-play-state: paused;
  transition: animation-play-state 0.3s ease-out;
}
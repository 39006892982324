.product-page {
    padding: 40px 0;
  }
  
  .product-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .back-link {
    color: var(--primary-black);
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .back-link:hover {
    color: var(--primary-red);
  }
  
  .cart-link {
    position: relative;
    color: var(--primary-black);
    font-size: 1.5rem;
  }
  
  .cart-count {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: var(--primary-red);
    color: var(--primary-white);
    font-size: 0.8rem;
    padding: 2px 6px;
    border-radius: 50%;
  }
  
  .product-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }
  
  .product-gallery {
    display: grid;
    gap: 20px;
  }
  
  .product-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .product-image:hover {
    transform: scale(1.02);
  }
  
  .product-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .product-title {
    font-size: 2rem;
    color: var(--primary-black);
    margin-bottom: 10px;
  }
  
  .product-price {
    font-size: 1.5rem;
    color: var(--primary-red);
    font-weight: bold;
  }
  
  .product-description {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #666;
  }
  
  .product-sizes h3,
  .product-features h3 {
    margin-bottom: 15px;
    color: var(--primary-black);
  }
  
  .size-options {
    display: flex;
    gap: 10px;
  }
  
  .size-button {
    padding: 10px 20px;
    border: 2px solid #ddd;
    background: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .size-button:hover,
  .size-button.active {
    border-color: var(--primary-red);
    color: var(--primary-red);
  }
  
  .product-features ul {
    list-style: none;
    padding: 0;
  }
  
  .product-features li {
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
  }
  
  .product-features li::before {
    content: '•';
    position: absolute;
    left: 0;
    color: var(--primary-red);
  }
  
  .product-actions {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
  
  .quantity-selector {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .quantity-button {
    background: none;
    border: 1px solid #ddd;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .quantity-button:hover {
    border-color: var(--primary-red);
    color: var(--primary-red);
  }
  
  .quantity-input {
    width: 50px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px;
  }
  
  .add-to-cart-button {
    flex: 1;
    padding: 15px 30px;
    background-color: var(--primary-red);
    color: var(--primary-white);
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .add-to-cart-button:hover {
    background-color: var(--primary-yellow);
    color: var(--primary-black);
  }
  
  .add-to-cart-button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  @media (max-width: 768px) {
    .product-content {
      grid-template-columns: 1fr;
    }
  
    .product-actions {
      flex-direction: column;
    }
  
    .size-options {
      flex-wrap: wrap;
    }
  
    .product-gallery {
      order: -1;
    }
  }